<template>
  <div id="page-content-wrapper" class="admin-content-area">
    <common-loader 
      refId="commonLoaderCalendar" 
      ref="commonLoaderCalendar">
    </common-loader>
    <div class="container-fluid xyz">
      <div class="spaced-container">
        <div class="col-12 page_title paddingnone">
          <div class="page_title_left">
            <span>Calendar</span>
          </div>
          <a
              id="refreshList"
              title="Refresh"
              class="extraFeature btn btn-primary waves-effect pull-right ml-2"
              v-on:click.prevent="syncOutlookEvents()"
            >
              <i class="fa fa-undo" aria-hidden="true"></i>
            </a>
        </div>
        <div class="col-12 paddingnone">
          
          <full-calendar
            id="calendar"
            :header="headerToolbar"
            :events="events"
            :config="config"
            ref="calendar"
          ></full-calendar>
        </div>

        <app-footer></app-footer>
      </div>
    </div>
    <!-- modal day clicked -->
    <div class="modal fade" id="dayClickedModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="page-header-top-container">
                    <div class="page-name"> Select an option!</div>
                    <button class=" btn-primary waves-effect pull-right" data-dismiss="modal">close</button>           
          </div>
          <div class="modal-body admin-page-content ">
            <div class="d-flex justify-content-center mt-4 mb-4">
              <button
                class="btn btn-primary mr-2"
                @click="handleOpportunityClicked"
                style="font-size: 1.2rem"
              >
                Add Opportunities
              </button>
              <button
                class="btn btn-primary mx-2"
                @click="handleActivityClicked"
                style="font-size: 1.2rem"
              >
                Add Activities
              </button>
              <button
                class="btn btn-primary ml-2"
                @click="handleReminderClicked"
                style="font-size: 1.2rem"
              >
                Add Reminders
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal reminder clicked -->
    <div class="modal fade" id="remindermodal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Set Reminder</h5>
          </div>

          <div class="modal-body">
            <div class="form-group">
              <label for="addTitle">Add Title</label>
              <input
                type="text"
                class="form-control"
                id="addTitle"
                placeholder="Add Title"
                v-model="reminderTitle"
              />
              <p class="text-danger" style="font-size: 0.9rem">
                {{ reminderTitleErrorMsg }}
              </p>
            </div>
            <div class="form-group">
              <label for="addDate">Date</label>
              <date-picker
                v-model="reminderDate"
                class="form-control"
                autocomplete="off"
                placeholder=""
                name="reminderDate"
                :config='options'
              >
              </date-picker>
              <p class="text-danger" style="font-size: 0.9rem">
                {{ reminderDateErrorMsg }}
              </p>
            </div>
            <div class="form-group">
              <label for="addDesc">Descriptions</label>
              <textarea
                class="form-control"
                id="addDesc"
                rows="4"
                placeholder="Add Descriptions"
                v-model="reminderDescription"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="handleReminderStore"
            >
              Save
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- modal reminder clicked -->
    <div class="modal fade" id="remindershowmodal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Reminders for {{ reminderShowDate }}</h5>
          </div>
          <div class="modal-body" style="max-height: 300px; overflow-y: auto">
            <template v-for="reminder in reminderList">
              <div :key="reminder.id" class="mb-4">
                <p class="m-0">{{ reminder.title }}</p>
                <p class="m-0 text-muted">{{ reminder.reminder_date }}</p>
                <p class="m-0">Description:</p>
                <p v-html="reminder.description" class="text-muted"></p>
              </div>
            </template>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

	<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" id="outlookCalenderEventShow">
		<div class="modal-dialog modal-xl" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">{{ selectedEvent.title }}</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body d-flex" style="flex: 1;" 
						v-if="typeof selectedEvent.event_from != 'undefined' 
								&& selectedEvent.event_from == 'OutlookEvent'">
					<div class="content-section" style="width: 75%;">
						<div class="d-flex align-items-center mb-2">
							<i class="fa fa-clock-o mr-2"></i>
							<p class="m-0">
							{{ selectedEvent.start }} - {{ selectedEvent.end }}
							</p>
						</div>
						<hr>
						<div class="d-flex align-items-center mb-2">
							<i class="fa fa-video-camera"></i>
							<a
							:href="formattedMeetingDetails.link"
							class="btn btn-primary btn-sm"
							style="margin-left: 8px"
							target="_blank"
							>
							{{ allLabelMessages.join_meeting }}
							</a>
						</div>
						<hr>	
						<div class="d-flex align-items-start mb-2">
						<i class="fa fa-bars"></i>
						<div
							style="margin-left: 8px"
							v-html="selectedEvent.body"
						></div>
						</div>
					</div>
					<!-- Attendees Section: 1/4 width -->
					<div class="attendees-section" style="width: 25%; padding-left: 15px; border-left: 1px solid #ddd;">
						<h5
							class="modal-title bold mb-20 pb-10"
							style="padding-top: 14px"
						>
							{{ allLabelMessages.tracking }}
						</h5>
						<hr class="separator" />
						<h6 class="modal-title mb-20 pb-10">{{ allLabelMessages.organizer }}</h6>
						<div class="d-flex align-items-center mb-2">
							<div class="email-circle" style="background-color: darksalmon">
								{{ selectedEvent.organizer_name ? selectedEvent.organizer_name.charAt(0).toUpperCase() : selectedEvent.organizer_email.charAt(0).toUpperCase() }}
							</div>
							<p class="m-0 ml-2">
								{{ selectedEvent.organizer_name }}
								<small>{{ selectedEvent.organizer_email }}</small>
							</p>
						</div>
						<hr class="separator" />

						<div v-if="selectedEvent.attendees && selectedEvent.attendees.length">
							<h6 class="modal-title mb-20 pb-10">{{ allLabelMessages.attendees }}</h6>
							<div v-for="(attendee, index) in selectedEvent.attendees"
								:key="'attendee_' + index"
								class="d-flex align-items-center mb-2">
								<div class="email-circle" style="background-color: steelblue">
									{{ attendee.attendee_name ? attendee.attendee_name.charAt(0).toUpperCase() : attendee.attendee_email.charAt(0).toUpperCase() }}
								</div>
								<p class="m-0 ml-2">
									{{ attendee.attendee_name }}<br><small>{{ attendee.is_required }}</small>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>
	
	<add-update-activity-popup
      v-if="showAddActivityForm"
      @call-close-add-activity-popup="callDayClickedModalClose"
      ref="addUpdateActivityPopupRef"
    ></add-update-activity-popup>

    <add-update-opportunity-popup
      v-if="showAddOpportunityForm"
      @call-close-add-opportunity-popup="callDayClickedModalClose"
      ref="addUpdateOpportunityPopupRef"
    ></add-update-opportunity-popup>
  </div>
</template>
<script>
var self;
import Footer from "@/components/partials/Footer";

import { commonMixins } from "../../mixins/commonMixins";
import EventBus from ".././event-bus";
import { HTTP } from "../../http-common";
import { FullCalendar } from "vue-full-calendar";
import "fullcalendar/dist/fullcalendar.css";
import AddUpdateActivityPopup from "../ContactMaster/AddUpdateActivityPopup.vue";
import AddUpdateOpportunityPopup from "../ContactMaster/AddUpdateOpportunityPopup.vue";
import datePicker from "vue-bootstrap-datetimepicker";
import CommonLoader from "@/components/partials/CommonLoader.vue";

var moment = require("moment-timezone");

export default {
	name: "Calendar",
	mixins: [commonMixins],
	components: {
		"app-footer": Footer,
		"full-calendar": FullCalendar,
		"add-update-activity-popup": AddUpdateActivityPopup,
		"add-update-opportunity-popup": AddUpdateOpportunityPopup,
		"date-picker": datePicker,
		"common-loader": CommonLoader,
	},
	data() {
		return {
		selectedDate: "",
		headerToolbar: {
			left: "prev,next",
			center: "title",
			right: "", // Remove right side navigation buttons
		},
		calendarTimeStampFormat: 'YYYY-MM-DD HH:mm:ss',
		calendarDateFormat: 'YYYY-MM-DD',
		events: [],
		config: {
			html: true,
			defaultDate: moment(new Date()).format(this.calendarDateFormat),
			defaultView: "month",
			displayEventTime: true,
			noEventsMessage: "Calendar Events Not Available",
			timeFormat: 'h:mm A',
			eventRender: function (info, element) {
			element
				.find(".fc-title")
				.html(info.title +
					+ info.count
				);
			},
			dayClick: (date, jsEvent, view) => {
			var selectDate = moment(date).format(this.calendarDateFormat);
			var currentDate = moment(new Date()).format(this.calendarDateFormat);

			if (selectDate < currentDate) {
				return false;
			} else {
				this.handleDayCLicked(selectDate);
				return false;
			}
			},
			eventClick: function (info) {
			self.handleEventClicked(info);
			},
			viewRender: function (view, element) {
			// Call your function here
			self.viewYear = view.end.year();
			self.viewMonth = view.end.month();
			if (self.viewMonth == 0) {
				self.viewMonth = 12;
				self.viewYear -= 1;
			}
			self.getEvents(self.viewMonth, self.viewYear);
			},
		},
		reminderTitle: "",
		reminderTitleErrorMsg: "",
		reminderDate: moment().format(process.env.VUE_APP_SYSTEM_DISPLAY_DATETIME_FORMAT),
		reminderDateErrorMsg: "",
		reminderDescription: "",
		viewYear: "",
		viewMonth: "",
		reminderShowDate: "",
		reminderList: [],
		options: {
			format: process.env.VUE_APP_SYSTEM_DISPLAY_DATETIME_FORMAT,
			useCurrent: false,
			allowInputToggle: true,
			widgetPositioning: {
			horizontal: "auto",
			vertical: "bottom"
			},
		},
		selectedEvent: {},
		showAddActivityForm: false,
		showAddOpportunityForm: false,
		accessToken: "",
		accessState:"",
		};
	},
	beforeMount() {
		self = this;
	},
	created: function () {
		self = this;
		self.getLoginIdPermissions();
	},
	watch: {
		reminderTitle(newVal) {
		if (newVal != "" || newVal != null) {
			self.reminderTitleErrorMsg = "";
		}
		},
		reminderDate(newVal) {
		if (newVal != "" || newVal != null) {
			self.reminderDateErrorMsg = "";
		}
		},
	},
	computed: {
		formattedMeetingDetails() {
			return self.formatMeetingDetails(self.selectedEvent.body_preview);
		}
	},

	mounted: function () {

		$('#searchCompanyModal').on('hidden.bs.modal', function (e) {
			$('body').addClass('modal-open');
		});
		let userObj = localStorage.getItem("user_obj");
		if (userObj == null) {
			self.$router.push("/");
		}

		//if user redirected from Outlook succesfully login then this two params found in URL
		self.accessToken = self.$route.query.code;
		self.accessState = self.$route.query.state;


		if ( self.accessToken != undefined ) {
			self.syncOutlookEvents();
		}

		self.setActiveMenu("calender");
		EventBus.$emit("menu-selection-changed", "calendar");
	},
	methods: {
		formatMeetingDetails(body_preview) {
			const meetingDetails = {
				link: ''
			};
			// Extract the Zoom meeting link
			const linkMatch = body_preview.match(/Join Zoom Meeting\s*(.*)/);

			if (linkMatch) {
				meetingDetails.link = linkMatch[1];
			}

			return meetingDetails;
		},

		syncOutlookEvents: function () {
			
			self.showLoader();
			var queryString = '';
			var config = {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("api_token"),
				},
			};
			
			//if url redirect from outlook with code then set accesstoken
			if ( self.accessToken != undefined && self.accessToken != '' ) {
				var params = {
					accessState: self.accessState,
					accessToken: self.accessToken,
				};

				// Create query string from parameters
				queryString = Object.keys(params)
					.map(key => encodeURIComponent(key) + '=' + params[key])
					.join('&');
			}

			// Construct URL with query parameters
			var url = "/outlook/calendars/sync" + (queryString ? '?' + queryString : '');
			
			HTTP.get(url, config)
			.then((response) => {
				self.hideLoader();
				//reload api event 
				self.getEvents(self.viewMonth, self.viewYear);
				self.showSAlert(response.data.message, "success", false, 3);
			})
			.catch(function (err) {
				
				if ( err.response.status == 402 ) {
					console.log(err.response.data.url);
					window.location.href = err.response.data.url;
				}
				self.hideLoader();
				self.catchHandler(err, function () {});
			});
		},
		handleReminderClicked() {
			self.reminderTitle = "";
			self.reminderTitleErrorMsg = "";
			self.reminderDateErrorMsg = "";
			self.reminderDescription = "";
			self.reminder =  moment().format(process.env.VUE_APP_SYSTEM_DISPLAY_DATETIME_FORMAT);
			let currentTime = moment().format("HH:mm:ss");
			let reminderDate = `${self.selectedDate} ${currentTime}`;
			self.reminderDate = moment(reminderDate,self.calendarTimeStampFormat).format(process.env.VUE_APP_SYSTEM_DISPLAY_DATETIME_FORMAT);
			$("#dayClickedModal").modal("hide");
			$("#remindermodal").modal("show");
		},
		handleReminderStore() {
			let error = false;
			if (self.reminderTitle == null || self.reminderTitle == "") {
				error = true;
				self.reminderTitleErrorMsg = "Title is required!";
			}
			if (self.reminderDate == null || self.reminderDate == "") {
				error = true;
				self.reminderDateErrorMsg = "Date is required!";
			}

			self.reminderDate = self.apiRequestDateTimeFormat(self.reminderDate);
			if (!error) {
				$("#remindermodal").modal("hide");
				self.showLoader();
				var config = {
					headers: {
						Authorization: "Bearer " + localStorage.getItem("api_token"),
					},
				};
				var data = new FormData();
				data.append("title", self.reminderTitle);
				data.append("reminder_date", self.reminderDate);
				data.append("description", self.reminderDescription);
				data.append("user_id", JSON.parse(localStorage.getItem("user_obj")).id);
				HTTP.post("/reminder/store", data, config)
				.then((response) => {
					self.hideLoader();
					self.showSAlert(response.data.message, "success", false, 3);
					self.getEvents(self.viewMonth, self.viewYear);
				})
				.catch(function (err) {
					self.hideLoader();
					self.catchHandler(err, function () {});
				});
			}
		},
		handleOpportunityClicked() {
			let opprotunityDate =  moment(self.selectedDate).format(process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT);
			let contactData = {
				id: "",
				excedeId: "",
				name: "",
				dateOpen: opprotunityDate,
			};
			this.showAddOpportunityForm = true;
			this.$nextTick(() => {
				this.$refs.addUpdateOpportunityPopupRef.openAddOpportunityPopup(contactData);
			});
		},
		callDayClickedModalClose(){
			self.getEvents(self.viewMonth, self.viewYear);
			$("#dayClickedModal").modal("hide");
		},
		handleActivityClicked() {
			let currentTime = moment().format("HH:mm:ss");
			let activityDate = `${self.selectedDate} ${currentTime}`;
			activityDate = moment(activityDate,self.calendarTimeStampFormat).format(process.env.VUE_APP_SYSTEM_DISPLAY_DATETIME_FORMAT);
			let contactData = {
				id: "",
				excedeId: "",
				name: "",
				activityTime: activityDate,
				flag: "",
			};
			this.showAddActivityForm = true;
			this.$nextTick(() => {
				this.$refs.addUpdateActivityPopupRef.openAddActivityPopup(contactData);
			});
		},
		handleEventClicked(info) {
			let date = info.start.toISOString().split("T")[0];
			if (info.title == "Activities: ") {
				self.$router.push({
				path: "/activity/list",
				query: { activeTime: date },
				});
			} else if (typeof info.event_from != 'undefined' && info.event_from == "OutlookEvent") {
				self.selectedEvent = {
				id: info.id,
				event_from: info.event_from,
				title: info.title,
				body_preview: info.body_preview,
				start: info.start_datetime,
				end: info.end_datetime
				};
				$('#outlookCalenderEventShow').modal('show');
				self.getCalendarEvent(info.id)
			} else if (info.title == "Reminders: ") {
				self.reminderShowDate = moment(date).format(process.env.VUE_APP_SYSTEM_DISPLAY_DATE_FORMAT);
				self.getReminders(info.reminder_ids);
			} else {
				if(process.env.VUE_APP_PRODUCTION_COUNTRY == 'AU'){
				self.$router.push({
					path: "/opportunity/list",
					query: { openDate: date },
				});
				}else{
				self.$router.push({
					path: "/opportunity/list",
					query: { decisionDate: date },
				});
				}
				
			}
		},
		getCalendarEvent(eventId){
			var config = {
				headers: {
				Authorization: "Bearer " + localStorage.getItem("api_token"),
				},
			};

			HTTP.get("/calendar/events/get/"+eventId, config)
				.then(function (response) {
					if (response.data.success) {
					let responseData = response.data.data;
					self.selectedEvent = {
						id: responseData.id,
						event_from: 'OutlookEvent',
						title: responseData.subject,
						body_preview: responseData.body_preview,
						start: responseData.start_datetime,
						end: responseData.end_datetime,
						attendees: responseData.attendees,
						body: responseData.body,
						organizer_email: responseData.organizer_email,
						organizer_name: responseData.organizer_name
					};
					}
				})
				.catch(function (err) {
					self.catchHandler(err, function () {});
				});

		},
		getReminders(reminderIds) {
			$("#remindershowmodal").modal("show");
			var config = {
				headers: {
				Authorization: "Bearer " + localStorage.getItem("api_token"),
				},
			};
			var data = new FormData();
			data.append("date", self.reminderShowDate);
			data.append("reminderIds", reminderIds);
			HTTP.post("/reminder/list", data, config)
				.then((response) => {
				self.reminderList = response.data.content;
				})
				.catch(function (err) {
				self.catchHandler(err, function () {});
				});
		},
		handleDayCLicked(date) {
			self.selectedDate = date;
			$("#dayClickedModal").modal("show");
		},
		getEvents(month, year) {
			$("#commonLoaderCalendar").find(".loading-container.lodingTable").show();
			var config = {
				headers: {
				Authorization: "Bearer " + localStorage.getItem("api_token"),
				},
			};
			var data = new FormData();
			data.append("month", month);
			data.append("year", year);
			HTTP.post("/calendar/events/get", data, config)
				.then((response) => {
				$("#commonLoaderCalendar").find(".loading-container.lodingTable").hide();
				self.events = response.data;
				})
				.catch(function (err) {
				$("#commonLoaderCalendar").find(".loading-container.lodingTable").hide();
				self.catchHandler(err, function () {});
				});
		},
	},
};
</script>

<style>
/* Add custom CSS to create gap between prev and next buttons */
.fc-toolbar .fc-prev-button {
  margin-right: 20px; /* Adjust the value as needed */
}

.fc-day-button {
  display: inline-block;
  width: 50%;
  text-align: center;
  margin-bottom: 5px;
}
.fc-title {
  color: white;
}

.modal-body-tracking {
  flex: 0 0 300px;
  padding-left: 20px;
  border-left: 1px solid #ddd;
}

.separator {
  border: 0;
  height: 1px;
  background-color: #ddd;
  margin: 10px 0 20px;
}

.email-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
}

.d-flex {
  display: flex;
}

.ml-2 {
  margin-left: 8px;
}
</style>
